<template>
  <div>
    <v-toolbar
      v-if="$route.name !== 'settings-mobile'"
      class="hidden-sm-and-up"
      color="transparent"
      flat
    >
      <v-btn
        icon
        text
        :to="$route.name === 'settings-mobile' ? { name: 'more' } : { name: 'settings-mobile' }"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h4 class="mb-0 settings-toolbar-title">
        {{ SettingsTitles[$route.name] }}
      </h4>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col
          class="hidden-xs-only"
          cols="12"
          sm="4"
          md="3"
        >
          <SettingsList />
        </v-col>
        <v-col
          cols="12"
          sm="8"
          md="9"
        >
          <router-view :create-alert="createAlert" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SettingsList from '@/pages/settings/components/SettingsList.vue'
import SettingsTitles from '@/config/settingsTitles.js'
import { mapState } from 'vuex'

export default {
  name: 'Settings',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  components: {
    SettingsList
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    }
  },
  data () {
    return {
      SettingsTitles
    }
  },
  computed: {
    ...mapState([
      'brandName'
    ])
  }
}
</script>
