import { i18n } from '@/plugins/i18n'

const SettingsTitles = {
  settings: i18n.tc('Pages.Settings.account_settings'),
  communication: i18n.tc('Pages.Settings.notification_settings'),
  privacy: i18n.tc('Pages.Settings.privacy'),
  'payment-details': 'Payment Details',
  'subscription-setup': 'Membership Options',
  'subscription-active': 'Subscriptions'
}

export default SettingsTitles
